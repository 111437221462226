import _ from 'lodash';
import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import { handleAlertSaveSuccess } from '../actions/view';
import CommonMetadataTable from 'common/components/MetadataTable';
import { localizeLink } from 'common/locale';
import WatchDatasetButton from './WatchDatasetButton/WatchDatasetButton';
import { FeatureFlags } from 'common/feature_flags';
import {
  currentUserIsSiteMember,
  currentUserIsSuperAdmin,
  currentUserIsLoggedIn,
  currentUserHasRight
} from 'common/current_user';
import DomainRights from 'common/types/domainRights';

export function mapStateToProps(state) {
  const view = state.view || {};
  const { coreView, customMetadataFieldsets } = view;

  const enableCreateAlertButtonForAllUser =
    FeatureFlags.value('enable_alert_notifications_for_all_users') === true;
  const enableCreateAlertButtonForAdmin =
    FeatureFlags.value('enable_alert_notifications_for_admin_users') === true;
  const enableCreateAlertButtonForRolledUser =
    FeatureFlags.value('enable_alert_notifications_for_role_users') === true;

  let showCreateAlertButton = false;
  let showMuteDatasetButton = false;

  if (currentUserIsLoggedIn()) {
    showMuteDatasetButton = true;
    if (enableCreateAlertButtonForAllUser) {
      showCreateAlertButton = true;
    } else if (enableCreateAlertButtonForAdmin && (currentUserIsSuperAdmin() || currentUserHasRight(DomainRights.manage_users))) {
      showCreateAlertButton = true;
    } else if (enableCreateAlertButtonForRolledUser && (currentUserIsSiteMember() || currentUserIsSuperAdmin())) {
      showCreateAlertButton = true;
    }
  }

  // We're mutating coreView for now (follow-up ticket to fix is EN-24645).
  // We do the translating in rails right now. Would need to reproduce in javascript.
  const coreViewCopy = _.cloneDeep(coreView);
  const license = _.get(coreViewCopy, 'license', {});
  license.name = view.licenseName || license.name || '';
  coreViewCopy.license = license;

  return ({
    analysisId: view.analysisId,
    localizeLink,
    coreView: coreViewCopy,
    customMetadataFieldsets: customMetadataFieldsets || [],
    disableContactDatasetOwner: view.disableContactDatasetOwner,
    renderWatchDatasetButton() {
      if (
        _.get(window, 'sessionData.email', '') !== '' &&
        _.get(window, 'serverConfig.enableNotifications')
      ) {
        return (<WatchDatasetButton view={view} />);
      } else {
        return null;
      }
    },
    showCreateAlertButton,
    showMuteDatasetButton
  });
}

function mergeProps(stateProps, { dispatch }) {
  return {
    ...stateProps,
    onExpandTags: () => {
      // noop since the removal of m*xpanel, but we may need to add some sort of tracking here
    },

    onExpandMetadataTable: () => {
      // noop since the removal of m*xpanel, but we may need to add some sort of tracking here
    },

    onAlertSaveSuccess() {
      dispatch(handleAlertSaveSuccess());
    }
  };
}

export default connect(mapStateToProps, null, mergeProps)(CommonMetadataTable);
