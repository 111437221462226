import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

export class HrefDownload extends Component {
  hasAccessPoints(allAccessPoints) {
    // empty hrefs have an allAccessPoints attribute like [{}]
    return !_.isEmpty(allAccessPoints) && !(allAccessPoints.length === 1 && _.isEmpty(allAccessPoints[0]));
  }

  renderUSAIDUploadPrompt() {
    return (
      // untranslated because this is a USAID specific feature :(
      <div className="section-content">
        <div className="button-group">
          <span>There are no associated datasets.</span>
          <br />
          <a
            href={'/datasets/new?beta=true&data_asset=false'}
            className="btn btn-primary btn-sm btn-alternate-2">
            <span>Create a new Dataset</span>
          </a>
        </div>
      </div>
    );
  }

  renderLinks(allAccessPoints) {

    if (!this.hasAccessPoints(allAccessPoints)) {
      return;
    }

    const content = _.map(allAccessPoints, (accessPoint, i) => {
      let buttons = _.map(accessPoint.urls, (url, mimeType) => (
        <a className="btn btn-primary btn-sm download all-caps" href={url} target="_blank" key={mimeType}>
          {mimeType}
        </a>
      ));

      if (accessPoint.describedBy) {
        buttons.push(
          <a
            key="data-dictionary"
            className="btn btn-simple btn-sm download"
            href={accessPoint.describedBy}
            target="_blank">
            {I18n.href_download.data_dictionary}
          </a>
        );
      }

      return _.isEmpty(accessPoint) ? null : (
        <div key={i} className="download-object">
          <div className="download-title">{accessPoint.title}</div>

          <p className="download-description">{accessPoint.description}</p>

          <div className="download-buttons">{buttons}</div>
        </div>
      );
    });

    return <div className="section-content">{content}</div>;
  }

  render() {
    const { view } = this.props;
    const { isHref } = view;
    const { allAccessPoints } = this.props.view;
    const hasAccessPoints = this.hasAccessPoints(allAccessPoints);

    // if it's not an href dataset, don't even show the title and section div
    if (!isHref && !hasAccessPoints) {
      return null;
    }

    let hrefTitle;

    hrefTitle = (<h2 className="landing-page-section-header">{I18n.href_download.title}</h2>);

    return (
      <section className="landing-page-section href-download dataset-download-section">
        {hrefTitle}
        {this.renderLinks(allAccessPoints)}
      </section>
    );
  }
}

HrefDownload.propTypes = {
  view: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return _.pick(state, 'view');
}

export default connect(mapStateToProps)(HrefDownload);
